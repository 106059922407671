<template>
    <div style="margin:20px;background:white;">
        <el-table
             ref="refTable"  class="table el-table__cell"
            :data="tableList" border
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: 5 + 'px' }"
        @expand-change="openhang"
        @row-click="clickTable"
        :expand-row-keys="expands"
            height="600px"
            :row-key="getRowKeys"
        >
          <el-table-column type="selection" width="50"  :selectable="selectable" :reserve-selection="true"/>
            <el-table-column type="expand">
                <template #default="props">
                    <el-button type="primary" @click="openAddContacts" style="margin-bottom:20px">添加意向客户联系记录</el-button>
                    <el-button @click="addContacts" style="margin-bottom:20px" type="primary">添加联系人</el-button>
                    <el-button type="primary" style="margin-bottom:20px" @click="addOrder">添加订单</el-button>
                    <div style="margin:20px 0"> <crm-contactList  :contactList="props.row.crmContactList" :rowId="props.row.id" ref="crmContactList"/></div>
                    <div style="margin:20px 0"> <crmCustRelationList :custRelationList="props.row.crmCustRelationList" :rowId="props.row.id" ref="crmCustRelationList"/></div>
                    <div style="margin:20px 0"> <ordersList :ordersList="props.row.crmOrdersList" ref="ordersList" :row="props.row"/></div> 
                </template>
            </el-table-column>
            <el-table-column prop="companyName" width="160" label="公司名称" show-overflow-tooltip />
            <el-table-column prop="name" width="120" show-overflow-tooltip label="联系人"/>
            <el-table-column prop="phone" label="手机号" min-width="130"/>
            <el-table-column  label="客户地址" min-width="160" show-overflow-tooltip>
                <template #default="scope">
                    <div>{{ scope.row.province + '-' + scope.row.city }}</div>
                </template>
            </el-table-column>
            <el-table-column  label="下次预约时间" min-width="180" prop="reservationRelationTime" />
            <el-table-column prop="custTypeIdName" width="120" show-overflow-tooltip label="客户类型"/>

            <el-table-column prop="createName" label="创建人"/>
            <el-table-column prop="source" label="客户来源" width="120" show-overflow-tooltip>
                <template #default="scope">            
                    <div :style="scope.row.source == null ? 'color:rgba(0,0,0,.3)' : '' ">
                        {{ scope.row.source == null ? '暂无来源' : scope.row.source}}
                    </div>
                </template>
            </el-table-column>
          
            <el-table-column prop="signState" label="签单状态" width="100">
                <template #default="scope">
                    {{ scope.row.signState == 0 ? '未签单' : '已签单'}}
                </template>
            </el-table-column>
            <el-table-column prop="thisStateName" label="状态"/>
          <el-table-column prop="isEndIntention" label="最近联系意向程度" min-width="140" show-overflow-tooltip/>
          <el-table-column prop="isEndIntentionTime" label="最后联系跟进时间" min-width="160" show-overflow-tooltip/>
          <el-table-column prop="isEndOrderTime" label="最后成单日期" width="170"/>
            <el-table-column prop="createTime" label="创建时间" min-width="180"/>
            <el-table-column  label="操作" min-width="100"  fixed="right">
                <template #default="scope">
                    <el-button type="text" @click.stop="edit(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
         
        </el-table>
        <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="current"
          :total="total"
          @current-change="handlePageChange"
        ></el-pagination> 
      </div>
        <Edit-client  :clientTypeList="clientTypeList" :thisStateList="thisStateList" ref="EditClient" :editObj="editObj" @editSucess="editSucess" />
    </div>
    
</template>
<script>
    import crmContactList from './AttractCrmContact.vue'
    import crmCustRelationList from './crmCustRelation.vue'
    import ordersList from './orders.vue'
    import EditClient from './editClient.vue'
    export default{ 
        props:{ 
            tableList:Array,
            clientTypeList:Array,
            thisStateList:Array,
            total:Number,
            current:Number
        },
        components:{
            crmContactList,
            crmCustRelationList,
            ordersList,
            EditClient
        },
        data(){ 
            return{ 
                contactList:[],
                custRelationList:[],
                ordersList:[],
                editObj:{},
                rowId:null,
                row:{},
                expands: [], //只展开一行放入当前行id
                getRowKeys(row) {
                    return row.id;
                },
            }
        },
        methods:{
          clickTable(row){
            this.$refs.refTable.toggleRowExpansion(row)
          },
                edit(e){ 
                    this.editObj = e
                    this.$refs.EditClient.openDialog()
                    },
                handlePageChange(e){ 
                  this.$emit('handlePageChange',e)
                },
                //打开添加意向客户记录
                openAddContacts(){
                  this.$refs.crmContactList.openAddContacts(); 
                },
                //打开添加联系人
                addContacts(){
                  this.$refs.crmCustRelationList.addContacts()
                },
                //打开添加订单
                addOrder(){
                    this.$refs.ordersList.addOrder()
                },
                openhang(row, expandedRows) {
                // console.log(row);
                let that = this;
                // console.log(expandedRows);
                //只展开一行
                if (expandedRows.length) {
                    //说明展开了
                    that.expands = [];
                    if (row) {
                    that.expands.push(row.id); //只展开当前行id
                    }
                } else {
                    //说明收起了
                    that.expands = [];
                }
                // let orderInfo = {order:{}};
                },
            }
        }

</script>
<style scoped>
 /* .pagination{ 
    position: absolute;
    bottom:5%;
    right: 5%;    
    } */
</style>
<style  type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}
</style>