<template>
    <div>
        <!-- <el-dialog v-model="record" title="订单列表" width="1100px" style="padding:30px"> -->
            <!-- <el-button type="primary" style="margin-bottom:20px" @click="addOrder">添加订单</el-button> -->
            <el-table :data="ordersList" border  class="table el-table__cell"
            :row-style="{height:2+'px'}"
            :cell-style="{color:'#909399',fontSize:'12.5px',padding:'0px 0px'}"
            :header-cell-style="{background:'#ffff !important;'}"
            >
                <el-table-column label="客户名称" prop="name"/>
                <el-table-column label="客户手机号" prop="phone" min-width="110"/>
                <el-table-column label="客户公司名称" prop="companyName" min-width="110"/>
                <el-table-column label="签单时间" prop="signOrderTime" min-width="160"/>
                <el-table-column label="合作类型" prop="signTypeName"/>
                <el-table-column label="保证金" prop="earnestMoney"/>
                <el-table-column label="签约金额" prop="money"/>
                <el-table-column label="实收金额" prop="practicalMoney"/>
                <el-table-column label="合同状态" prop="contractStateName"/>
              <el-table-column label="合同图片" >
                <template #default="scope" >
                  <el-image class="code"
                            hide-on-click-modal
                            v-if="scope.row.contractImg"
                            :src="scope.row.contractImg"
                            :preview-src-list="[scope.row.contractImg]"/>
                </template>
              </el-table-column>
              <el-table-column label="收款图片" >
                <template #default="scope" >
                  <el-image class="code"
                            hide-on-click-modal
                            v-if="scope.row.receiptImg"
                            :src="scope.row.receiptImg"
                            :preview-src-list="[scope.row.receiptImg]"/>
                </template>
              </el-table-column>
                <el-table-column label="签单人" prop="signCoachName"/>
                <el-table-column label="财务确认订单状态" prop="financeStateName"/>
                <el-table-column label="创建时间" prop="createTime" min-width="160" />
                <el-table-column label="备注说明" prop="remark"/>
                <el-table-column label="操作">
                    <template #default="scope"> 
                        <el-button type="text" @click="editOrder(scope.row)">
                            编辑
                        </el-button>

                      <el-button type="text" @click="returnMoney(scope.row)">
                        退费
                      </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 添加订单 -->
        <el-dialog title='添加订单'  v-model="adDialog" width="1100px">
            <el-form :rules="orderRules" :model="query" ref="ruleForm" style="padding:20px" label-width="120px">
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="公司名称">
                            <el-input disabled v-model="query.companyName"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户名称">
                            <el-input disabled v-model="query.name"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户手机号">
                            <el-input disabled v-model="query.phone"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="签单时间" prop="signOrderTime">
                            <el-date-picker
                                v-model="query.signOrderTime"
                                type="datetime"
                                style="width:240px"
                                @blur="changeTime"
                                placeholder="请选择联系时间"
                                format="YYYY/MM/DD hh:mm:ss"
                                value-format="YYYY-MM-DD h:m:s a"
                            />
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="合作类型" prop="signType">
                            <el-select v-model="query.signType" style="width:240px">
                                <el-option v-for="(item,i) in signTypeList" :key="i" :value="item.dictValue" :label="item.dictLabel"></el-option>
                            </el-select>
                         </el-form-item>
                    </el-col>
                  <el-row>
                    <el-col :span="8" v-for="(item,index) in query.moneyType" :key="index">
                      <el-form-item :label="item.dictLabel" :prop="`moneyType.${index}.dictType`"
                                    :rules="{ required: true, message: '请输入', trigger: 'blur'}">
                        <el-input type="number" v-model="item.dictType"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-row>
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="合作(签约)金额" prop="money">
                            <el-input v-model="query.money"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="实收金额" prop="practicalMoney">
                            <el-input v-model="query.practicalMoney"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="收款类型" prop="ordersType">
                            <el-input v-model="query.ordersType" placeholder="例定金、尾款"></el-input>
                         </el-form-item>
                    </el-col>
                  
                </el-row>
                <el-row>
                    <el-col :span="16">
                         <el-form-item label="备注说明">
                            <el-input  :rows="2"
                                type="textarea" v-model="query.remark"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="商户单号" prop="partnerId">
                            <el-input  
                                 v-model="query.partnerId"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                         <el-form-item label="合同图片" prop="contractImg">
                            <el-upload
                                v-if="!img"
                                ref='upload'
                                class="upload"
                                :http-request="uploadCocah"
                                :before-upload="beforeAvatarUpload"
                                :on-remove="handleRemove"
                                :limit="1"
                                list-type="picture-card">
                           <el-button icon="Plus" text />
                            </el-upload>   
                             <el-image v-if="img" style="width: 150px; height: 150px" :src="img"/>              
                            <!-- <el-input v-model="query.contractImg"></el-input> -->
                         </el-form-item>
                    </el-col>  
                    <el-col :span="10">
                         <el-form-item label="收款图片" prop="receiptImg">
                            <el-upload
                                v-if="!receiptImg"
                                ref='upload'
                                class="upload"
                                :http-request="uploadProceeds"
                                :before-upload="beforeAvatarUpload"
                                :on-remove="handleRemove"
                                :limit="1"
                                list-type="picture-card">
                           <el-button icon="Plus" text />
                            </el-upload>   
                             <el-image v-if="receiptImg" style="width: 150px; height: 150px" :src="receiptImg"/>              
                            <!-- <el-input v-model="query.contractImg"></el-input> -->
                         </el-form-item>
                    </el-col>              
                </el-row>
            </el-form>
            <template #footer>
            <el-button @click="adDialog = false"> 取消 </el-button>
            <el-button @click="confirm" type="primary"> 确定 </el-button>
         </template>
        </el-dialog>
            <!-- 编辑订单 -->
        <el-dialog title='编辑订单' v-model="editDialog" width="1100px">
            <el-form style="padding:20px" label-width="100px">
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="公司名称">
                            <el-input v-model="editQuery.companyName"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户名称">
                            <el-input v-model="editQuery.name"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户手机号">
                            <el-input v-model="editQuery.phone"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="签单时间">
                            <el-date-picker
                                v-model="editQuery.signOrderTime"
                                type="datetime"
                                style="width:240px"
                                @blur="changeTime"
                                placeholder="请选择联系时间"
                                format="YYYY/MM/DD hh:mm:ss"
                                value-format="YYYY-MM-DD h:m:s a"
                            />
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="合作类型">
                            <el-select v-model="editQuery.signType" style="width:240px">
                                <el-option v-for="(item,i) in signTypeList" :key="i" :value="item.dictValue" :label="item.dictLabel"></el-option>
                            </el-select>
                         </el-form-item>
                    </el-col>
                    <el-row>
                    <el-col :span="8" v-for="(item,index) in editQuery.crmOrdersMoneyType" :key="index">
                      <el-form-item :label="item.dictName"
                                    :rules="{ required: true, message: '请输入', trigger: 'blur'}">
                        <el-input type="number" v-model="item.money"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                </el-row>
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="签约金额">   
                            <el-input v-model="editQuery.money"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="实收金额">
                            <el-input v-model="editQuery.practicalMoney"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="收款类型">
                            <el-input v-model="editQuery.ordersType" placeholder="例定金、尾款"></el-input>
                         </el-form-item>
                    </el-col>
                  
                </el-row>
                <el-row>
                    <el-col :span="16">
                         <el-form-item label="备注说明">
                            <el-input  :rows="2"
                                type="textarea" v-model="editQuery.remark"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="商户单号">
                            <el-input  
                                 v-model="editQuery.partnerid"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                         <el-form-item label="合同图片">
                            <div style="display:flex">
                                <div>更新前图片:</div>
                                <el-image  style="width: 150px; height: 150px" :src="editImg"/>      
                                <el-upload
                                    v-if="!editQuery.contractImg"
                                    ref='upload'
                                    class="upload"
                                    :http-request="edituploadCocah"
                                    :before-upload="beforeAvatarUpload"
                                    :on-remove="handleRemove"
                                    :limit="1"
                                    list-type="picture-card">
                               <el-button icon="Plus" text />
                                </el-upload>  
                                <div style="margin-left:20px">更新后图片:</div>
                                <el-image v-if="editQuery.contractImg"  style="width: 150px; height: 150px" :src="editQuery.contractImg"/>     
                            </div>            
                           
                         </el-form-item>
                </el-row>
                <el-row>
                         <el-form-item label="收款图片">
                            <div style="display:flex">
                                <div>更新前图片:</div>
                                <el-image  style="width: 150px; height: 150px" :src="editReceiptImg"/>      
                                <el-upload
                                    v-if="!editQuery.receiptImg"
                                    ref='upload'
                                    class="upload"
                                    :http-request="edituploadProceeds"
                                    :before-upload="beforeAvatarUpload"
                                    :on-remove="handleRemove"
                                    :limit="1"
                                    list-type="picture-card">
                               <el-button icon="Plus" text />
                                </el-upload>  
                                <div style="margin-left:20px">更新后图片:</div>
                                <el-image v-if="editQuery.receiptImg"  style="width: 150px; height: 150px" :src="editQuery.receiptImg"/>     
                            </div>            
                           
                         </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
            <el-button @click="editDialog = false"> 取消 </el-button>
            <el-button @click="editConfirm" type="primary"> 确定 </el-button>
         </template>
        </el-dialog>
      <return-money ref="returnMoney"/>
    </div>
</template>
<script>
import {selectDictByType} from '@/api/dict.js'
import { uploadImage} from '@/api/helpManual'
import ReturnMoney from "./ReturnMoney";
import {
    addCrmOrders,
    updateCrmOrdersById,
    checkPaymentImages
} from '@/api/attract'
export default{
  components: {
    ReturnMoney
  },
  props:{
      ordersList:Array,
      row:Object
  },
  inject:['getData'],
  data(){
      return{
          record:false,
          adDialog:false,
          editDialog:false,
          query:{
            partnerId:null,//商户单号
            companyName:'',
            receiptImg:'',//收款图片
            contractImg:'',//合同图片
            crmCustId:null,
            earnestMoney:null,//保证金
            moneyType:[],
            money:null,//签约金额
            name:'',
            ordersType:null,//收款类型
            phone:null,
            practicalMoney:null,//实收金额
            remark:'',//备注
            signOrderTime:'',//合同签订时间
            signType:null,//合作类型
            contractState:null,//合同状态
            signId:null,//签单人Id
            province:'',//省
            city:''//市
          },
        orderRules: {
          signOrderTime: [{ required: true, message: "请选择签单时间", trigger: "blur" }],
          signType: [{ required: true, message: "请选择合作类型", trigger: "blur" }],
          money: [{ required: true, message: "请输入合同签约金额", trigger: "blur" }],
          practicalMoney:[{ required: true, message: "请输入实收金额", trigger: "blur" }],
          ordersType: [{ required: true, message: "请输入收款类型", trigger: "blur" }],
          partnerId: [{ required: true, message: "请输入商户单号", trigger: "blur" },],
          receiptImg: [{ required: true, message: "请选择收款图片", trigger: "blur" }],
          contractImg: [{ required: true, message: "请选择合同图片", trigger: "blur" },],
        },
          editQuery:{

          },
          signTypeList:[],
          img:'',
          receiptImg:'',
          editImg:'',
          editReceiptImg:''
      }
  },
  methods:{
      returnMoney(item){
        console.log(this.$refs.returnMoney);
        this.$refs.returnMoney.openDialog(item);
      },
      openDialog(){
          this.record = true
      },
      addOrder(){
          this.record = false,
          this.adDialog = true
          this.query.companyName = this.row.companyName
          this.query.name =  this.row.name
          this.query.phone = this.row.phone
          selectDictByType('signType').then(res=>{
              if(res.code == 200){
                  this.signTypeList = res.data;

              }else{
                  this.$message.error('请求合作类型失败！')
              }
          })
      },
    checkPaymentImages(){
      checkPaymentImages(this.receiptImg).then(val =>{
        if (val.code == 200 && val.data.temp.payNo!=null && val.data.temp.payNo!=''){
          this.query.partnerId = val.data.temp.payNo;
          this.$message.success('商户单号识别成功！')
        }else {
          this.$message.error('识别单号失败，请手动输入！')
        }
      })
    },
      changeTime(){
          if(this.query.signOrderTime){
              this.query.signOrderTime = this.dateFormat(this.query.signOrderTime)
          }else{
              this.query.signOrderTime = ''
          }
      },
      dateFormat(val) {
          var date = new Date(val);
          var sign2 = ":";
          var year = date.getFullYear() // 年
          var month = date.getMonth() + 1; // 月
          var day = date.getDate(); // 日
          var hour = date.getHours(); // 时
          var minutes = date.getMinutes(); // 分
          var seconds = date.getSeconds() //秒
          // var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
          // var week = weekArr[date.getDay()];
          // 给一位数的数据前面加 “0”
          if (month >= 1 && month <= 9) {
              month = "0" + month;
          }
          if (day >= 0 && day <= 9) {
              day = "0" + day;
          }
          if (hour >= 0 && hour <= 9) {
              hour = "0" + hour;
          }
          if (minutes >= 0 && minutes <= 9) {
              minutes = "0" + minutes;
          }
          if (seconds >= 0 && seconds <= 9) {
              seconds = "0" + seconds;
          }
          return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      },
      // 收款图片上传
      uploadProceeds(e){
          const data = new FormData()
         let fileName = 'attract/'
          data.append(
              'multipartFile', e.file
          )
          data.append(
              'fileName', fileName
          )
          data.append(
              'flag', 'attract'
          )
           uploadImage(data).then(res=>{
              if(res.code==200){
              this.receiptImg = res.data
              this.query.receiptImg = res.data
              this.checkPaymentImages();
              }else{
              this.$message.error('上传失败！')
              }
          })
      },
      //合同图片上传
      uploadCocah(e){
         const data = new FormData()
         let fileName = 'attract/'
          data.append(
              'multipartFile', e.file
          )
          data.append(
              'fileName', fileName
          )
          data.append(
              'flag', 'attract'
          )
          uploadImage(data).then(res=>{
              if(res.code==200){
              this.img = res.data
              this.query.contractImg = res.data;
              }else{
              this.$message.error('上传失败！')
              }
          })
      },
      edituploadCocah(e){
          const data = new FormData()
         let fileName = 'attract/'
          data.append(
              'multipartFile', e.file
          )
          data.append(
              'fileName', fileName
          )
          data.append(
              'flag', 'attract'
          )
          uploadImage(data).then(res=>{
              if(res.code==200){
              this.editQuery.contractImg = res.data
              }else{
              this.$message.error('上传失败！')
              }
          })
      },
      edituploadProceeds(e){
          const data = new FormData()
         let fileName = 'attract/'
          data.append(
              'multipartFile', e.file
          )
          data.append(
              'fileName', fileName
          )
          data.append(
              'flag', 'attract'
          )
          uploadImage(data).then(res=>{
              if(res.code==200){
              this.editQuery.receiptImg = res.data
              }else{
              this.$message.error('上传失败！')
              }
          })
      },
      confirm(){
        console.log(this.query)
        let total = 0;
        let name = '';
        this.query.moneyType.forEach(function(item,index){
          total = total + Number(item.dictType);
          if (index == 0){
            name = '（'+item.dictLabel;
          }else {
            name = name + "+"+item.dictLabel;
          }
        })
        if (total != this.query.practicalMoney){
          this.$message.error(name+"）不等于 实收金额")
          return;
        }
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            this.query.crmCustId = this.row.id
            this.query.province = this.row.province
            this.query.city = this.row.city
            addCrmOrders(this.query).then(res=>{
              this.adDialog = false
              if(res.code == 200){
                this.$message.success('添加成功！')
                this.getData()
              }else{
                this.$message.error('添加失败')
              }
            })
          }
        })
      },
      editConfirm(){
          if(!this.editQuery.contractImg){
              this.editQuery.contractImg = this.editImg
          }
          updateCrmOrdersById(this.editQuery).then(res=>{
              this.editDialog = false
              if(res.code == 200){
                  this.$message.success('编辑成功！')
                  this.getData()
              }else{
                  this.$message.error('编辑失败失败')
              }
          })
      },
      editOrder(e){
          console.log(e);
          this.record = false
          this.editDialog = true
          this.editQuery = JSON.parse(JSON.stringify(e))
          this.editQuery.contractImg = ''
          this.editQuery.receiptImg = ''
          this.editImg = JSON.parse(JSON.stringify(e)).contractImg
          this.editReceiptImg = JSON.parse(JSON.stringify(e)).receiptImg
      }
  },
  created() {
    selectDictByType('orderMoneyType').then(res=>{
      if(res.code == 200){
        this.query.moneyType = res.data;
      }else{
        this.$message.error('请求订单金额类型！')
      }
    })
  }
}
</script>
<style>
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
</style>
<style  type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}
</style>