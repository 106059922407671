<template>
    <div>
        <el-dialog title="编辑意向客户" v-model="record" width="1000px">
            <el-form style="padding:20px" label-width="100px" ref="addClient" :rules="rules" :model="query">
                <el-row>
                    <el-col :span="8">
                         <el-form-item label="公司名称" prop="companyName">
                            <el-input v-model="query.companyName"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户姓名" prop="name">
                            <el-input v-model="query.name"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户手机号" label-width="110px" prop="phone">
                            <el-input v-model="query.phone"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户来源" prop="source">
                            <el-input v-model="query.source" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                         <el-form-item label="客户类型" prop="custTypeId">
                            <el-select style="width: 210px"
                                class="mr10" placeholder="客户类型" v-model="query.custTypeId"
                                clearable  
                            >
                            <el-option v-for="(item,i) in clientTypeList" :key="i" :value="item.dictValue*1" :label="item.dictLabel"> </el-option>
                            </el-select>
                         </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户当前状态" label-width="110px" prop="thisState">
                            <el-select style="width: 200px" class="mr10" placeholder="意向客户当前状态 " v-model="query.thisState" clearable>
                                <el-option v-for="(item,i) in thisStateList" :value="item.dictValue*1" :label="item.dictLabel" :key="i"></el-option>
                            </el-select>  
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
         <template #footer>
            <el-button @click="record = false"> 取消 </el-button>
            <el-button @click="editClient" type="primary"> 确定 </el-button>
         </template>
        </el-dialog>
    </div>
</template>
 <script>
 import {updateById} from '../../../api/attract'
    export default{ 
        props:{
            clientTypeList:Array,
            thisStateList:Array,
            editObj:Object
        },
        inject:['getData'],
        data(){ 
            return{ 
                record:false,
                query:{
                    companyName:'',
                    name:'',
                    phone:null,
                    custTypeId:null,
                    signState:null,
                    thisState:null
                },
                rules:{
                companyName:[
                        { 
                        required: true,
                        message: '请输入公司名称', 
                        trigger: 'blur' 
                        },
                    ],
                name:[
                        { 
                        required: true,
                        message: '请输入客户姓名', 
                        trigger: 'blur' 
                        },
                    ],
                phone:[
                        { 
                        required: true,
                        message: '请输入客户手机号', 
                        trigger: 'change' 
                        },
                        { pattern:/^1[3-9]\d{9}$/, message: '目前只支持中国大陆的手机号码' }
                    ],
                source:[
                        { 
                        required: true,
                        message: '请输入客户来源', 
                        trigger: 'blur' 
                        },
                    ],
                // custTypeId:[
                //         { 
                //         required: true,
                //         message: '请选择客户类型', 
                //         trigger: 'change' 
                //         },
                //     ],   
                // thisState:[
                //         { 
                //         required: true,
                //         message: '请选择客户当前状态', 
                //         trigger: 'change' 
                //         },
                //     ],     
                }   

            }
        },
        methods:{ 
            openDialog(){ 
                this.record = true
            },
            editClient(){
                this.$refs.addClient.validate((valid)=>{ 
                    if(valid){ 
                        updateById(this.query).then(res=>{ 
                            if(res.code == 200){ 
                                this.$message.success('更新成功')
                                this.record = false
                                this.getData()
                            }else{ 
                                this.$message.error(res.message)
                            }
                        })
                    }else{ 
                        this.$message.error('请完整填写信息！')
                    }
                })
                

            },
        },
        watch:{ 
            editObj:{ 
                handler(newVal){ 
                    this.query = JSON.parse(JSON.stringify(newVal)) 
                },
                deep: true, // 深度监听
            }
        }
    }

</script>
<style scope>
    .mr10{ 
        margin-right: 10px;
    }
</style>