<template>
    <div>
        <!-- <el-dialog v-model="record" title="联系人" width="900px"> -->
            <!-- <el-button @click="addContacts" style="margin-bottom:20px" type="primary">添加联系人</el-button> -->
            <el-table :data="custRelationList" border>
                <el-table-column label="联系人姓名" prop="name"/>
                <el-table-column label="手机号" prop="phone"/>
                <el-table-column label="创建时间" prop="createTime"/>
                <el-table-column label="备注说明" prop="remark"/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button
                            type="text"
                            @click="handleEdit(scope.$index, scope.row)"
                        >编辑
                        </el-button>
                        <el-button
                            type="text"
                            style="color:red;"
                            @click="deleteContact(scope.$index, scope.row)"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <template #footer>
                <el-button @click="record=false">取消</el-button>
                <el-button type="primary">确定</el-button>
            </template>
        </el-dialog> -->
        <el-dialog v-model="adDialog" title="添加联系人" width="900px">
            <el-form style="padding:20px" label-width="100px">
                <el-row>
                    <el-col :span="10">
                         <el-form-item label="联系人姓名">
                            <el-input v-model="query.name"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="10">
                         <el-form-item label="手机号">
                            <el-input v-model.number="query.phone"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                         <el-form-item label="备注说明">
                            <el-input 
                                v-model="query.remark" 
                                :rows="2"
                                type="textarea"
                                placeholder="请输入联系内容" />
                         </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="adDialog=false">取消</el-button>
                <el-button type="primary" @click="confirm">确定</el-button>
            </template>
        </el-dialog>
        <el-dialog v-model="editDialog" title="编辑联系人" width="900px">
            <el-form style="padding:20px" label-width="100px" :rules="rules" :model="parmas">
                <el-row>
                    <el-col :span="10">
                         <el-form-item label="联系人姓名" prop="name">
                            <el-input v-model="parmas.name"></el-input>
                         </el-form-item>
                    </el-col>
                    <el-col :span="10">
                         <el-form-item label="手机号" prop="phone">
                            <el-input v-model.number="parmas.phone"></el-input>
                         </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                         <el-form-item label="备注说明" prop="remark">
                            <el-input 
                                v-model="parmas.remark" 
                                :rows="2"
                                type="textarea"
                                placeholder="请输入联系内容" />
                         </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="editDialog=false">取消</el-button>
                <el-button type="primary" @click="editConfirm">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {addCrmCustRelation,deleteById,updateCrmCustRelationById} from '@/api/attract.js'
    export default{ 
        props:{ 
            custRelationList:Array,
            rowId:Number
        },
        inject:['getData'],
        data(){ 
            return{ 
                record:false,
                adDialog:false,
                editDialog:false,
                query:{
                    crmCustId:null,
                    name:'',
                    phone:null,
                    remark:''
                },
                parmas:{

                },
                reules:{
                    name:[
                        { 
                        required: true,
                        message: '请填写联系人', 
                        trigger: 'blur' 
                        },
                    ],
                    phone:[
                        { 
                        required: true,
                        message: '请填手机号', 
                        trigger: 'blur' 
                        },
                    ],
                }
            }
        },
        methods:{ 
            openDialog(){ 
                this.record = true
            },
            addContacts(){ 
                this.record = false,
                this.adDialog = true
            },
            confirm(){ 
                this.query.crmCustId = this.rowId
                
                addCrmCustRelation(this.query).then(res=>{ 
                    this.adDialog = false
                    this.record = false
                    if(res.code == 200){ 
                        this.$message.success('添加成功！')
                        this.getData()
                    }else{ 
                        this.$message.error(res.message)
                    }
                })
            },
            deleteContact(index,row){ 
                deleteById(row.id).then(res=>{  
                     this.adDialog = false
                     this.record = false
                   if(res.code == 200){           
                    this.$message.success('删除成功！')
                    this.getData()
                   }else{ 
                    this.$message.error('删除失败')
                   }
                })
            },
            handleEdit(index,row){ 
                this.editDialog = true
                this.record = false,
                this.parmas = JSON.parse(JSON.stringify(row))
            },
            editConfirm(){
                updateCrmCustRelationById(this.parmas).then(res=>{ 
                    this.editDialog = false
                    if(res.code == 200){ 
                        this.$message.success('更新成功！')
                        this.getData()
                    }else{ 
                        this.$message.error('操作失败！')
                    }
                })
            },
        },
    }
</script>