<template>
  <div>
    <div style="padding: 20px;display: flex;">
        <el-button type="primary" @click="add" style="margin-right:10px;">添加</el-button>
        <el-input  style="width: 200px" v-model="query.info" placeholder="手机号/客户公司 " class="handle-input mr10"></el-input>
        <el-select style="width: 180px" class="mr10" placeholder="几天未联系客户" v-model="query.day" clearable>
            <el-option label="3天" :value="3"></el-option>
            <el-option label="5天" :value="5"></el-option>
            <el-option label="7天" :value="7"></el-option>
        </el-select>
        <el-select style="width: 180px"
         class="mr10" placeholder="客户类型" v-model="query.custTypeIdList"
         clearable  
         multiple
         collapse-tags
         collapse-tags-tooltip
           >
            <el-option v-for="(item,i) in clientTypeList" :key="i" :value="item.dictValue" :label="item.dictLabel"> </el-option>
        </el-select>
        <!-- 选择部门 -->
        <el-cascader
            class="mr10"
            collapse-tags
            collapse-tags-tooltip
            clearable
            v-model="query.deptId"
            placeholder="请选择部门"
            :props="{
              multiple: true,
                value: 'id',
                label: 'name',
                children: 'children'}"
                :options="deptList"/>
        <el-select style="width: 120px" class="mr10" placeholder="签订状态" v-model="query.signState" clearable>
            <el-option label="未签订" :value="0"></el-option>
            <el-option label="已签订" :value="1"></el-option>
        </el-select>
      <el-select placeholder="数据类型" style="width: 160px" v-model="query.crmType" class="inPut">
        <el-option value="招商" label="招商"></el-option>
        <el-option value="线索" label="线索"></el-option>
      </el-select>
        <!-- 选择日期 -->
        <el-date-picker
            v-model="query.reservationRelationTime"
            type="datetime"
            class="mr10"
            style="width:270px"
            placeholder="请选择联系时间"
            format="YYYY/MM/DD hh:mm:ss"
            value-format="YYYY-MM-DD h:m:s a"
                            />
        <!--  -->
        <el-select style="width: 180px" class="mr10" placeholder="意向客户当前状态 " v-model="query.thisState" clearable>
            <el-option v-for="(item,i) in thisStateList" :value="item.dictValue" :label="item.dictLabel" :key="i"></el-option>
        </el-select> 
        <el-cascader
            class="mr10"
            placeholder="请选择地址"
            @change="changeAddress"
            :options="pcaTextArr"
             v-model="address">
        </el-cascader>
        <el-button type="primary" @click="searchData">搜索</el-button>      
        <el-button  type="primary" @click="importData">导入</el-button>
        <el-popconfirm
          title="确定导出吗？"
          @confirm="importDataExcell"
         >
          <template #reference>
            <el-button type="primary" class="margin10">导出</el-button>
            </template>
        </el-popconfirm>
    </div>
    <div>
        <Attract-table :tableList="tableList"  :clientTypeList="clientTypeList" :thisStateList="thisStateList" :total="total" :current="current" @handlePageChange="handlePageChange"/>
        <Add-client ref="AddClient"  :clientTypeList="clientTypeList" :thisStateList="thisStateList" />
    </div>
    <el-dialog v-model="importDialog" title="导入数据" width="600px">
        <div style="text-align: center;">
            <el-upload drag :limit=1 :auto-upload="false" :on-remove="handleRemove"
                     :on-change="fileChange" :on-exceed="exceedFile" accept=".xls , .xlsx">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { pcaTextArr } from 'element-china-area-data'
import {getData,crmCust,exportCrmCust} from '@/api/attract.js'
import {selectDictByType} from "@/api/dict.js"
import {listXcxDept} from "@/api/dept";
import AttractTable from './components/AttractTable.vue'
import AddClient from './components/AddClient.vue'
    export default { 
        components:{
            AttractTable,
            AddClient,
        },
        provide(){
            return{ getData:this.searchData }
        },
        data(){ 
            return{ 
                deptList:[],
                pcaTextArr,
                address:[],
                query:{ 
                    reservationRelationTime:'',
                    province:'',
                    city:'',
                    coachId:null,
                    custTypeIdList:[],
                    info:'',
                    pageIndex:1,
                    pageSize:10,
                    signState:null,
                    thisState:null,
                },
                thisStateList:[],//意向客户当前状态
                clientTypeList:[],//客服类型列表
                tableList:[],
                importDialog:false,
                fileList:[],
                total:null,
                current:null,
            }
        },
        created(){ 
            this.getList()   
        },
        methods:{ 
            // changeTime(){ 
            //     if(this.query.reservationRelationTime){ 
            //      this.query.reservationRelationTime = this.dateFormatCent(this.query.reservationRelationTime)
            //     }
            // },
            dateFormatCent(val) {
                var date = new Date(val);
                var sign2 = ":";
                var year = date.getFullYear() // 年
                var month = date.getMonth() + 1; // 月
                var day = date.getDate(); // 日
                var hour = date.getHours(); // 时
                var minutes = date.getMinutes(); // 分
                var seconds = date.getSeconds() //秒
                // var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
                // var week = weekArr[date.getDay()];
                // 给一位数的数据前面加 “0”
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (day >= 0 && day <= 9) {
                    day = "0" + day;
                }
                if (hour >= 0 && hour <= 9) {
                    hour = "0" + hour;
                }
                if (minutes >= 0 && minutes <= 9) {
                    minutes = "0" + minutes;
                }
                if (seconds >= 0 && seconds <= 9) {
                    seconds = "0" + seconds;
                }
                return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
            },
            importDataExcell(){ 
                exportCrmCust(this.query)
            },  
            changeAddress(){ 
                if(this.address.length>=2){ 
                    this.query.province = this.address[0]
                    this.query.city = this.address[1]
                }else{ 
                    this.address = []
                }
            },
            getTypeList(commodityType) {
                if (commodityType!=null){
                    commodityType.forEach(items => {
                    if (items!=null){
                        if (items.children!=null && items.children.length > 0) {
                        this.getTypeList(items.children);
                        } else {
                        items.children = undefined;
                        }
                    }
                    });
                    return commodityType;
                }
            },
            add(){
                this.$refs.AddClient.openDialog()
            },
            searchData(){
              if (this.query.deptId){
                this.query.deptId = [...this.query.deptId.flat()];
              }
                getData(this.query).then(res=>{
                   if(res.code == 200){ 
                    this.tableList = res.data.records
                    this.total = res.data.total
                    this.current = res.data.current
                   }else{ 
                    this.$message.error('获取数据失败')
                   }
                })
            },
            async getList(){ 
               await selectDictByType('custType').then(res=>{ 
                    if(res.code == 200){ 
                        this.clientTypeList = res.data
                    }else{ 
                        this.$message.error('获取客户类型失败')
                    }
                })
               await selectDictByType('custThisState').then(res=>{ 
                    if(res.code == 200){ 
                        this.thisStateList = res.data
                    }else{ 
                        this.$message.error('获取意向客户当前状态失败')
                    }
                })
                this.searchData()
                await listXcxDept().then(res => {
                    if(res.code == 200){ 
                         this.deptList = this.getTypeList(res.data);    
                    }
                   
                })        
            },
            importData(){ 
                this.importDialog = true
            },
            async fileChange(e){ 
                let form = new FormData();
                form.append("file",e.raw);
                await crmCust(form)
                this.importDialog = false
                this.searchData()
 
            },
            handlePageChange(e){ 
                this.query.pageIndex = e
                this.searchData()
            }
        }
    }
</script>
<style  scoped>
.mr10{
    margin-right: 10px;
}

</style>