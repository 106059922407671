<template>
    <div>
        <!-- <el-dialog v-model="record" title="记录列表" width="1000px"> -->
            <!-- <el-button type="primary" @click="openAddContacts" style="margin-bottom:20px">添加意向客户联系记录</el-button> -->
            <el-table :data="contactList" border>
                <el-table-column label="意向客户表Id" prop="crmCustId" min-width="100"></el-table-column>
                <!-- <el-table-column label="意向客户的多个联系人Id" prop="crmCustRelationId"></el-table-column> -->
                <el-table-column label="联系内容" prop="relationMatter"></el-table-column>
                <el-table-column label="发起联系人姓名" prop="relationName" min-width="100" />
                <el-table-column label="其他跟进要点说明" prop="remark" min-width="130" />
                <el-table-column label="录音路径" ></el-table-column>
                <el-table-column label="本次联系人">
                    <template #default="scope">
                        <el-popover placement="right" :width="500" trigger="click">
                            <template #reference>
                                <el-button style="margin-right: 16px">查看</el-button>
                            </template>
                            <div style="padding:20px" v-if="scope.row.crmCustRelation">
                            <div style="font-size:24px">第二联系人信息：</div>
                                <div class="flex_mar">
                                <div>第二联系人姓名：{{ scope.row.crmCustRelation.name }}</div>
                                <div>第二联系人电话：{{ scope.row.crmCustRelation.phone }}</div>
                                </div>
                                <div class="flex_mar">
                                    <div>创建时间：{{ scope.row.crmCustRelation.createTime }}</div>
                                    <!-- <div>状态：{{ scope.row.crmCustRelation.isDelete == 0 ? '已删除' : '未删除' }}</div> -->
                                </div>
                                <div class="flex_mar">
                                    其他跟进要点说明：{{ scope.row.crmCustRelation.remark }}
                                </div>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="联系时间" prop="relationTime" min-width="130" />
            </el-table>
            <!-- <template #footer> -->
                <!-- <el-button @click="record=false">取消</el-button> -->
                <!-- <el-button type="primary">确定</el-button> -->
            <!-- </template> -->
        <!-- </el-dialog> -->
        <el-dialog v-model="contacts" title="添加意向客户记录"  width="1000px">
            <el-form style="padding:20px" label-width="100px" ref="addRecord" :rules="addRecordRules" :model="query">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="联系人" prop="crmCustRelationId">
                            <el-select v-model="query.crmCustRelationId">
                                <el-option v-for="(item,i) in ortherContactList" 
                                :key="i" :label="item.name + '——' + item.phone" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>           
                    <el-col :span="10">
                        <el-form-item label="意向程度" prop="remark">
                            <el-input v-model="query.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="联系时间" prop="relationTime">
                            <el-date-picker
                                v-model="query.relationTime"
                                type="datetime"
                                style="width:270px"
                                @blur="changeTime"
                                placeholder="请选择联系时间"
                                format="YYYY/MM/DD hh:mm:ss"
                                value-format="YYYY-MM-DD h:m:s a"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item  label="截图" prop="recording">      
                                <el-upload
                                    v-model="query.recording"            
                                    :on-preview="handlePreview"
                                    :limit="1"
                                    :on-change = 'selcetFile'
                                >
                                    <el-button type="primary">点击我上传</el-button>
                                </el-upload>
                            <!-- <el-input v-model="query.d"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="下次预约时间">  
                            <el-date-picker
                                v-model="query.relationTime"
                                type="datetime"
                                style="width:270px"
                                @blur="changeTimes"
                                placeholder="请选择下次时间"
                                format="YYYY/MM/DD hh:mm:ss"
                                value-format="YYYY-MM-DD h:m:s a"
                            />              
                        </el-form-item>
                    </el-col>、
                    <el-col :span="8">
                        <el-form-item label="客户类型">                
                            <el-select style="width: 180px"
                            class="mr10" placeholder="客户类型" v-model="query.custTypeId"
                            clearable  
                            >
                                <el-option v-for="(item,i) in clientTypeList" :key="i" :value="item.dictValue" :label="item.dictLabel"> </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="联系内容" prop="relationMatter">
                            <el-input
                                v-model="query.relationMatter"
                                :rows="2"
                                type="textarea"
                                placeholder="请输入联系内容"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="contacts=false">取消</el-button>
                <el-button type="primary" @click="addRecord">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {selectListByCrmCustId,
    addCrmContact
} from '@/api/attract.js'
import {selectDictByType} from "@/api/dict.js"
    export default{ 
        inject:['getData'],
        data(){ 
            return{ 
                clientTypeList:[],//客服类型列表
                record:false,
                contacts:false,
                query:{
                    custTypeId:null,
                    crmCustId:null,
                    recording:'',//录音路径
                    crmCustRelationId:null,//联系人Id
                    relationMatter:'',//联系内容
                    relationTime:'',//联系时间
                    remark:''//意向程度
                },
                ortherContactList:[],
                addRecordRules:{
                    crmCustRelationId:[
                        { 
                        required: true,
                        message: '请选择联系人', 
                        trigger: 'change' 
                        },
                    ],
                    remark:[
                        { 
                        required: true,
                        message: '请输入意向程度', 
                        trigger: 'blur' 
                        },
                    ],
                    relationTime:[
                        { 
                        required: true,
                        message: '请选择联系时间', 
                        trigger: 'change' 
                        },
                    ],
                    // recording:[
                    //     { 
                    //     required: true,
                    //     message: '请上传录音路径', 
                    //     trigger: 'blur' 
                    //     },
                    // ],
                    relationMatter:[
                        { 
                        required: true,
                        message: '请输入联系内容', 
                        trigger: 'blur' 
                        },
                    ],
                }
            }
        },
        props:{
            contactList:Array,
            rowId:Number
        },
        created(){
          selectDictByType('custType').then(res=>{ 
            if(res.code == 200){ 
                    this.clientTypeList = res.data
            }else{ 
                     this.$message.error('获取客户类型失败')
                }
            })
        },
        methods:{
            openDialog(){ 
                this.record = true
            },
            openAddContacts(){
                this.contacts = true
                this.record = false
                selectListByCrmCustId(this.rowId).then(res=>{ 
                    if(res.code == 200){ 
                        this.ortherContactList = res.data
                    }else{ 
                        this.$message.error('请求联系人失败！')
                    }
                })
            },
            selcetFile(e){ 
                console.log(e);
            },
            changeTimes(){         
                if(this.query.reservationRelationTime) {
                    this.dateFormatCent(this.query.reservationRelationTime)
                }   
            },
            changeTime(){ 
                if(this.query.relationTime){ 
                 this.query.relationTime = this.dateFormatCent(this.query.relationTime)
                }
            },
            dateFormatCent(val) {
                var date = new Date(val);
                var sign2 = ":";
                var year = date.getFullYear() // 年
                var month = date.getMonth() + 1; // 月
                var day = date.getDate(); // 日
                var hour = date.getHours(); // 时
                var minutes = date.getMinutes(); // 分
                var seconds = date.getSeconds() //秒
                // var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
                // var week = weekArr[date.getDay()];
                // 给一位数的数据前面加 “0”
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (day >= 0 && day <= 9) {
                    day = "0" + day;
                }
                if (hour >= 0 && hour <= 9) {
                    hour = "0" + hour;
                }
                if (minutes >= 0 && minutes <= 9) {
                    minutes = "0" + minutes;
                }
                if (seconds >= 0 && seconds <= 9) {
                    seconds = "0" + seconds;
                }
                return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
            },
            addRecord(){ 
                this.query.crmCustId = this.rowId
                this.$refs.addRecord.validate((valid)=>{ 
                    if(valid){ 
                        addCrmContact(this.query).then(res=>{ 
                            if(res.code == 200){ 
                                this.$message.success('添加成功!')
                                this.getData()
                                this.contacts = false
                                this.record = false
                            }else{ 
                                this.$message.error(res.message)
                                this.contacts = false
                            }
                        })
                    }else{ 
                        this.$message.error('请完整填写信息')
                    }
                })
                
            }
        },
        // watch:{
        //     contactList:{
        //         handler(newVal){ 
        //            console.log(newVal);
        //            if(newVal){ 
        //             this.record = true
        //            }
        //         }
        //     }
        // }
    }
</script>
<style scoped>
.flex_mar{ 
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}
</style>